import moment from 'moment'
import constants from 'src/constants'
import {
  AttackSurfaceAutocompleteDataItem,
  AttackSurfaceFiltersType,
} from 'src/modals/attack-surface/autocomplete-widget'

interface DateRangeInterface {
  from?: string
  to?: string
}

interface MinMaxInterface {
  min?: number
  max?: number
}
interface TagInterface {
  id?: number
  title: string
  isKeyword?: boolean
}

export type IocsFilterDataType = {
  malwareSamples: string[]
  domains: string[]
  ips: string[]
}

export const getUserCompanyGroupNames = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const groupNames = params.getAll('group-name')
  if (!groupNames) return []
  return groupNames
}

export const getIocFiltersFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const iocs = params.getAll('ioc')
  if (!iocs) return []
  return iocs.map((ioc) => {
    const splittedData = ioc.split(',')
    return {
      id: splittedData[1],
      title: splittedData[1],
      parent: 'ioc',
      type: `Ioc -> ${splittedData[0]}`,
      iocType: splittedData[0],
    }
  })
}

export const getDateRangeFromUrl = (): DateRangeInterface => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const from = params.get('from')
  const to = params.get('to')
  if (from && to) {
    return { from, to }
  }
  return {}
}

export const getThreatFilterFromUrl = (): string[] => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const threatParams = params.get('threat')

  return threatParams
    ?.split(',')
    .filter((threatParam) => ['cve', 'technology', 'sector', 'country', 'adversary'].includes(threatParam))
}

export const getCompanyIdFilterFromUrl = (): string => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const companyId = params.get('companyId')

  return companyId
}

export const getSourceFilterFromUrl = (): string[] => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const sources = params.getAll('source')
  return sources.length ? sources : ['forums', 'comms_channels', 'marketplaces']
}

export const getOrderByFromUrl = (): { field?: string; direction?: string } => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const order = params.get('order')

  if (!order) return { field: 'date', direction: 'DESC' }

  const orderSplitted = order.split(',')
  return { field: orderSplitted[0], direction: orderSplitted[1] }
}

export const getExploitedFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const exploited = params.getAll('exploited')
  if (!exploited) return []
  return exploited
}

export const getMinMaxFromUrl = (type: string): MinMaxInterface => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)

  const min = params.get('min')
  const max = params.get('max')
  const maxLimit = type === 'score' ? 1000 : 100

  if (Number(min) === 0 && Number(max) === maxLimit) return {}

  if (Number(min) >= 0 && Number(max)) {
    return { min: Number(min), max: Number(max) }
  }

  return {}
}

export const getVulnsTags = () => {
  const url = new URLSearchParams(window.location.search)
  return [
    ...url.getAll('threatActor').map((tag) => ({ id: parseInt(tag.split(',')[0]), title: tag.split(',')[1] })),
    ...url.getAll('targetSector').map((tag) => ({ id: parseInt(tag.split(',')[0]), title: tag.split(',')[1] })),
    ...url.getAll('threatCategory').map((tag) => ({ id: parseInt(tag.split(',')[0]), title: tag.split(',')[1] })),
  ]
}

export const getTagsFromUrl = (param: string, type = 'tag') => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const tags = params.getAll(type).map((tag: string) => {
    const data = tag.split(',')
    switch (param) {
      case 'id':
        if (isNaN(parseInt(data[0]))) {
          return data[0]
        }
        return parseInt(data[0])
      case 'name':
        return data[1]
      case 'all':
        if (isNaN(parseInt(data[0])) || data.length === 1) {
          return { title: data[0], isKeyword: true }
        } else {
          if (data[2]) {
            return { id: parseInt(data[0]), title: data[1], type: data[2] }
          } else {
            return { id: parseInt(data[0]), title: data[1] }
          }
        }
      default:
        break
    }
  })

  const tagsIds = tags.filter((tag: any) => tag.id).map((tag) => tag.id)
  const tagsNames = tags.filter((tag) => !tag.id).map((tag: any) => tag.title)
  const tagsAll = tags.filter((tag: string | number) => typeof tag === 'object')
  return { tagsIds, tagsNames, tagsAll }
}

export const getTagsFromUrlNoKeyword = (param: string, type = 'tag') => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const tags = params.getAll(type).map((tag: string) => {
    const data = tag.split(',')
    switch (param) {
      case 'id':
        if (isNaN(parseInt(data[0]))) {
          return data[0]
        }
        return parseInt(data[0])
      case 'name':
        return data[1]
      case 'all':
        if (isNaN(parseInt(data[0])) || data.length === 1) {
          return { title: data[0] }
        } else {
          if (data[2]) {
            return { id: parseInt(data[0]), title: data[1], type: data[2] }
          } else {
            return { id: parseInt(data[0]), title: data[1] }
          }
        }
      default:
        break
    }
  })

  const tagsIds = tags.filter((tag: any) => tag.id).map((tag) => tag.id)
  const tagsNames = tags.filter((tag) => !tag.id).map((tag: any) => tag.title)
  const tagsAll = tags.filter((tag: string | number) => typeof tag === 'object')
  return { tagsIds, tagsNames, tagsAll }
}

export const getAttackSurfaceFiltersFromUrl = (
  search: string,
  createAutocompleteData = false
): AttackSurfaceFiltersType | AttackSurfaceAutocompleteDataItem[] => {
  const urlSearchParams = new URLSearchParams(search)
  const filters = {
    ips: urlSearchParams.get('ips')?.split(','),
    ports: urlSearchParams.get('ports')?.split(','),
    domains: urlSearchParams.get('domains')?.split(','),
    cves: urlSearchParams.get('cve')?.split(','),
    ccns: urlSearchParams.get('ccns')?.split(','),
    icns: urlSearchParams.get('icns')?.split(';'),
    ssls: urlSearchParams.get('ssls')?.split(';'),
    databases: urlSearchParams.get('databases')?.split(';'),
  }
  if (!createAutocompleteData) {
    const nonEmptyFilters = Object.keys(filters).reduce((acc, key: keyof AttackSurfaceFiltersType) => {
      if (filters[key]?.length) acc[key] = filters[key]
      return acc
    }, {} as AttackSurfaceFiltersType)

    return nonEmptyFilters
  }
  const filterInputValues = [
    ...(filters.ports?.map((port) => ({ title: port, type: 'port' })) || []),
    ...(filters.ips?.map((ip) => ({ title: ip, type: 'ip' })) || []),
    ...(filters.domains?.map((domain) => ({ title: domain, type: 'domain' })) || []),
    ...(filters.cves?.map((cve) => ({ title: cve, type: 'cve' })) || []),
    ...(filters.ccns?.map((ccn) => ({ title: ccn, type: 'ccn' })) || []),
    ...(filters.icns?.map((icn) => ({ title: icn, type: 'icn' })) || []),
    ...(filters.ssls?.map((ssl) => ({ title: ssl, type: 'ssl' })) || []),
    ...(filters.databases?.map((database) => ({ title: database, type: 'database' })) || []),
  ]

  return filterInputValues
}

export const getOpenCveIdFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const cveId = params.get('open-cve')
  return cveId
}

export const getPartialMatchFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const partialMatch = params.getAll('partialmatch')
  return partialMatch.length === 0 ? false : true
}

export const getAlertTypeAndIdFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const filterBy = params.get('filterBy')
  const userAlertSentId = params.get('id')
  return { filterBy, userAlertSentId }
}

export const getAlertFiltersFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const filters = params.get('filters')?.split(',')
  const alertOption = params.get('alertoption')
  const company = params.get('company')
  const companyIds = params.get('companyids') ? parseInt(params.get('companyids'), 10) : null
  const orderBy = params.get('orderby')?.split(',')
  let orderByParam
  if (orderBy?.length) {
    orderByParam = {
      field: orderBy[0],
      direction: orderBy[1] as 'ASC' | 'DESC',
    }
  }
  return { filters: filters || [], alertOption, companyIds, orderByParam, company }
}

export const getThirdPartyAlertTypeAndIdFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const filterBy = params.get('filterBy')
  const alertId = params.get('id')
  if (alertId && filterBy) return { filterBy, alertId: parseInt(alertId) }

  return null
}

export const getAlertDrawerOpenStateFromUrl = () => {
  const params = new URLSearchParams(location.search)
  const alertDrawer = params.get('alert-drawer-open')
  return alertDrawer === 'true'
}

interface FiltersArgs {
  tags?: any
  iocs?: any
  dateRange?: any
  sourceValues?: any
  severity?: any
  threatFilter?: any
  orderBy?: any
  countries?: any
  sectors?: any
  companies?: any
  cves?: any
  technologies?: any
  maxSeverity?: any
  partialMatch?: any
  ports?: string[]
  ips?: string[]
  domains?: string[]
  isActionedTable?: boolean
  companyGroups?: string[]
  ransomwareRelatedCVEs?: boolean
  otTag?: boolean
  exploitedByRansomware?: boolean
  openCveId?: string
  privacyTypes?: string[]
}

export type AlertsFiltersArgs = {
  alertOption?: string
  filters?: string[] | undefined
  company?: string
  companyIds?: any
  orderBy?: string
}

export const applyAlertsFiltersToUrl = ({ alertOption, filters, company, companyIds, orderBy }: AlertsFiltersArgs) => {
  const queryParams = []
  if (alertOption) queryParams.push(`alertoption=${alertOption}`)
  if (company) queryParams.push(`company=${company}`)
  if (filters && filters.length > 0) queryParams.push(`filters=${filters.join(',')}`)
  if (companyIds && company) queryParams.push(`companyids=${companyIds}`)
  if (orderBy) queryParams.push(`orderby=${orderBy}`)

  return queryParams.join('&')
}

export const applyUsersFiltersToUrl = ({ orderBy }) => {
  const queryParams = []
  if (orderBy) queryParams.push(`orderby=${orderBy}`)

  return queryParams.join('&')
}

export const applyFiltersToUrl = ({
  tags,
  iocs,
  dateRange,
  sourceValues,
  severity,
  threatFilter,
  orderBy,
  countries,
  sectors,
  technologies,
  companies,
  cves,
  maxSeverity = 100,
  partialMatch,
  ports,
  ips,
  domains,
  isActionedTable,
  companyGroups,
  ransomwareRelatedCVEs,
  exploitedByRansomware,
  openCveId,
  privacyTypes,
  ccns,
  icns,
  ssls,
  databases,
  nonAggregateSearch,
  otTag,
}: FiltersArgs) => {
  const queryParams = []
  // Push tags to url
  if (tags?.length > 0) {
    const tagsFormatted = `${tags
      .map((option): string =>
        option?.id
          ? `tag=${option.id},${encodeURIComponent(option.title.trim())}`
          : `tag=${encodeURIComponent(option.title)}`
      )
      .join('&')}`

    queryParams.push(tagsFormatted)
  }

  if (iocs?.length > 0) {
    const iocsFormatted = `${iocs
      .map((option): string => `ioc=${option.iocType},${encodeURIComponent(option.title.trim())}`)
      .join('&')}`

    queryParams.push(iocsFormatted)
  }

  // Push countries to url
  if (countries?.length > 0) {
    const countriesFormatted = `${countries
      .map((country): string => `country=${country.id},${country.title.trim()},${country.type}`)
      .join('&')}`

    queryParams.push(countriesFormatted)
  }
  // Push sectors to url
  if (sectors?.length > 0) {
    const sectorsFormatted = `${sectors
      .map((sector): string => `sector=${sector.id},${sector.title.trim()},${sector.type}`)
      .join('&')}`

    queryParams.push(sectorsFormatted)
  }

  if (technologies?.length > 0) {
    const technologiesFormatted = `${technologies
      .map((technology): string => `technology=${technology.id},${technology.title.trim()},${technology.type}`)
      .join('&')}`

    queryParams.push(technologiesFormatted)
  }

  // Push companies to url
  if (companies?.length > 0) {
    const companiesFormatted = `${companies
      .map((company): string => `company=${company.id},${company.title.trim()},${company.type}`)
      .join('&')}`

    queryParams.push(companiesFormatted)
  }

  // Push user company group names to url
  if (companyGroups?.length > 0) {
    const userCompaniesGroups = `${companyGroups
      .map((name): string => `group-name=${encodeURIComponent(name)}`)
      .join('&')}`
    queryParams.push(userCompaniesGroups)
  }
  // Push cves to url
  if (cves?.length > 0) {
    let cvesFormatted = ''
    if (cves.every((cve) => typeof cve === 'object'))
      cvesFormatted = `${cves.map((cve): string => `cve=${cve.id},${cve.title.trim()},${cve.type}`).join('&')}`

    if (cves.every((cve) => typeof cve === 'string')) cvesFormatted = `cve=${cves.join(',')}`

    queryParams.push(cvesFormatted)
  }

  // Push date range to url
  if (dateRange?.from && dateRange?.to) queryParams.push(`from=${dateRange.from}&to=${dateRange.to}`)

  if (sourceValues?.length > 0) {
    const sourceValuesFormatted = `${sourceValues.map((option): string => `source=${option}`).join('&')}`

    queryParams.push(sourceValuesFormatted)
  }

  // Push orderBy range to url
  if (orderBy?.field && orderBy?.direction) {
    queryParams.push(`order=${orderBy.field},${orderBy.direction}`)
  }

  // Push severity range to url
  if (severity?.min > 0 || severity?.max < maxSeverity) {
    queryParams.push(`min=${severity.min}&max=${severity.max}`)
  }

  // Push threatFilter range to url
  if (threatFilter) queryParams.push(`threat=${threatFilter}`)

  if (partialMatch) queryParams.push('partialmatch=true')

  //push filters for attack surface modals
  if (ports) queryParams.push(`ports=${ports}`)
  if (ips) queryParams.push(`ips=${ips}`)
  if (domains) queryParams.push(`domains=${domains}`)
  if (ccns) queryParams.push(`ccns=${ccns}`)
  if (icns) queryParams.push(`icns=${icns.join(';')}`)
  if (ssls) queryParams.push(`ssls=${ssls.join(';')}`)
  if (databases) queryParams.push(`databases=${databases.join(';')}`)
  if (isActionedTable) queryParams.push(`actionedtable=${isActionedTable}`)
  if (exploitedByRansomware) queryParams.push(`ransomware=${exploitedByRansomware}`)
  if (ransomwareRelatedCVEs) queryParams.push(`ransomware-related-cves=${ransomwareRelatedCVEs}`)
  if (otTag) queryParams.push(`ot-tag=${otTag}`)
  if (nonAggregateSearch) queryParams.push(`nonAggregateSearch=true`)

  // push cve to url to open cve modal
  if (openCveId) {
    const urlParams = location.search
    const params = new URLSearchParams(urlParams)
    if (params.size > 0) {
      queryParams.push(params)
    }
    queryParams.push(`open-cve=${openCveId}`)
  }

  if (privacyTypes?.length > 0) queryParams.push(`types=${privacyTypes}`)
  return queryParams.join('&')
}

export const applyTagFilterToUrl = (tagName: string, id: number): string => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const isTagInUrl = params
    .getAll('tag')
    .map((tag: string) => {
      return Number(tag.split(',')[0])
    })
    .includes(id)

  if (!isTagInUrl) {
    if (location.search) {
      let search = location.search

      return search.replace('?', `?tag=${id},${tagName}&`)
    } else {
      return `?tag=${id},${tagName}`
    }
  } else {
    const urlDecoded = encodeURI(`tag=${id},${tagName}`)
    return location.search.replace(urlDecoded, '')
  }
}

export const getMinDateRange = (isDefault: boolean, isIntrep = false): Date => {
  if (isDefault) {
    const date = isIntrep ? new Date(2007, 0, 1) : new Date(2013, 0, 1)
    return date
  } else {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth() - 6, date.getDate())
  }
}

export const getDateRangeDefaultParam = (): string => {
  const dateNow = moment().format('YYYY-MM-DD')
  return `from=${constants.INTREP_DATE_RANGE_FROM}&to=${dateNow}`
}

export const getIntelligencePageDateRange = () => {
  const dateRangeFromUrl = getDateRangeFromUrl()
  if (Object.keys(dateRangeFromUrl).length) return dateRangeFromUrl

  return {
    from: constants.INTELLIGENCE_PAGE_DEFAULT_DATE_RANGE_FROM,
    to: moment().format('YYYY-MM-DD'),
  }
}

export const getAttackSurfaceFilterDateRange = () => {
  const dateRangeFromUrl = getDateRangeFromUrl()
  if (Object.keys(dateRangeFromUrl).length) return dateRangeFromUrl

  return {
    from: constants.ATTACK_SURFACE_FILTER_DATE_RANGE_FROM,
    to: moment().format('YYYY-MM-DD'),
  }
}

export const getIsLoadingFile = (files: any[], companyId: number, companyScore, typeFile: string) => {
  return files.some(
    ({ id, type, scanIterId, date }) =>
      Number(id) === Number(companyId) &&
      type === typeFile &&
      scanIterId === companyScore?.scanIterId &&
      date === companyScore?.date
  )
}

export const getPromoCodeFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  return params.get('code')
}

export const getUserExistsFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  return params.get('userExists')
}

export const removeParamAndReturnUrl = (param: string, path?: string) => {
  const url = new URL(path, window.location.origin)
  url.searchParams.delete(param)
  return url.pathname + url.search
}

export const addParamToUrl = (param: string, value: string) => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  params.set(param, value)
  return `${location.origin}${location.pathname}?${params.toString()}`
}

export const getIocsDropdownData = (IocsData: IocsFilterDataType) => {
  const { malwareSamples, domains, ips } = IocsData
  const malwareSamplesOptions = malwareSamples?.map((malwareSample, index) => ({
    id: `${malwareSample}-id-${index}`,
    title: malwareSample,
    type: 'Ioc -> Malware Sample',
    parent: 'ioc',
    iocType: 'malwareSample',
  }))

  const domainIocsOptions = domains?.map((domain, index) => ({
    id: `${domain}-id-${index}`,
    title: domain,
    type: 'Ioc -> Domain',
    parent: 'ioc',
    iocType: 'domain',
  }))

  const ipIocsOptions = ips?.map((ip, index) => ({
    id: `${ip}-id-${index}`,
    title: ip,
    type: 'Ioc -> Ip',
    parent: 'ioc',
    iocType: 'ip',
  }))
  return { malwareSamplesOptions, domainIocsOptions, ipIocsOptions }
}

export const getPrivacyDataTypesFromUrl = () => {
  const urlParams = location.search
  const params = new URLSearchParams(urlParams)
  const filters = params.get('types')?.split(',')
  return filters || []
}
