import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  CircularProgress,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { persistor, rootStore } from 'src/redux/store'
import { theme } from 'src/styling'
import { gql as GQL } from 'apollo-boost'
import client from 'src/network/apollo-client'
import SnackBar from 'src/context/snackbar'
import axios from 'axios'
import { getJwtPayload } from 'src/auth/payload'

// ===================
// QUERIES & RESOURCES
// ===================
const UPDATE_ACCEPTED_EULA = GQL`
  mutation updateUserAcceptedEula {
    updateUserAcceptedEula
  }
`

const useStyles = makeStyles({
  dialogButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
  },
  buttonSubmit: {
    minWidth: '9rem',
    height: '2.25rem',
    fontSize: theme.fontSizes.mediumLarge,
    backgroundColor: theme.colors.ui.blueLight,
    color: theme.colors.ui.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.ui.blueDark,
      color: theme.colors.ui.white,
    },
  },
  buttonDecline: {
    backgroundColor: theme.colors.ui.grey,
    color: theme.colors.ui.white,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.colors.ui.greyLight,
      color: theme.colors.ui.white,
    },
  },
  a: {
    color: theme.colors.ui.black,
  },
})

const TermsOfUse = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = SnackBar.Consumer()

  const state = rootStore.getState()
  const dispatch = useDispatch()

  const { termsOfUseAcceptedDate } = state.auth
  const [open, setOpen] = useState(termsOfUseAcceptedDate === null)
  const [loading, setLoading] = useState(false)
  const [termsOfUseUrl, setTermsOfUseUrl] = useState(null)
  const { companyId } = getJwtPayload()
  // Show modal only if termsOfUseAcceptedDate is null
  useEffect(() => {
    setOpen(termsOfUseAcceptedDate === null)
  }, [termsOfUseAcceptedDate])
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
  }

  useEffect(() => {
    axios
      .get(`/termsOfUse/${companyId}/download/pdf`)
      .then((res) => {
        setTermsOfUseUrl(res.data)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }, [])

  const confirmLogout = (): void => {
    dispatch({
      type: 'LOGOUT',
      payload: {
        nextUrl: '/',
      },
    })
  }

  const handleConfirmOrganisationClick = async () => {
    setLoading(true)
    try {
      const response = await client.mutate({
        mutation: UPDATE_ACCEPTED_EULA,
      })
      const { termsOfUseAcceptedDate } = JSON.parse(response.data.updateUserAcceptedEula)

      dispatch({
        type: 'LOGIN',
        payload: { termsOfUseAcceptedDate },
      })

      persistor.flush().then(() => window.location.reload())
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} onClose={handleClose}>
        <DialogTitle>End-User License Agreement</DialogTitle>
        <DialogContent>
          <p style={{ fontSize: '14px' }}>
            Please review our{' '}
            <a className={classes.a} href={termsOfUseUrl} target="_blank" rel="noopener noreferrer">
              End-User License Agreement (EULA)
            </a>{' '}
            before proceeding.
            <br />
            <br />
            By clicking ‘Agree & Proceed’ or using this software, you confirm that you have read, understand, and accept
            the terms of the End-User License Agreement (EULA).
            <br /> <br />
            If you do not agree, you must not access or use this software.
          </p>
        </DialogContent>
        <DialogActions className={classes.dialogButtons}>
          <Button className={classes.buttonDecline} disabled={loading} onClick={confirmLogout}>
            Decline & Exit
          </Button>
          <Button className={classes.buttonSubmit} disabled={loading} onClick={handleConfirmOrganisationClick}>
            {loading ? <CircularProgress style={{ color: 'white' }} size={20} /> : 'Agree & Proceed'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TermsOfUse
