import { OrganisationTabsType } from 'src/routers/router-helpers'
import DeepDarkWebPageImage from 'src/images/navbar/tooltip-page-icons/deep-dark-web-page.png'
import AlertsPageImage from 'src/images/navbar/tooltip-page-icons/alerts-page.png'
import IntelligencePageImage from 'src/images/navbar/tooltip-page-icons/intelligence-page.png'
import ProfilesPageImage from 'src/images/navbar/tooltip-page-icons/profiles-page.png'
import ThirdPartyRiskPageImage from 'src/images/navbar/tooltip-page-icons/third-party-risk-page.png'
import InternalRBVMPageImage from 'src/images/navbar/tooltip-page-icons/internal-rbvm-page.png'
import ExternalASMPageImage from 'src/images/navbar/tooltip-page-icons/external-asm-page.png'
import ReportsPageImage from 'src/images/navbar/tooltip-page-icons/reports-page.png'
import YourReportPageImage from 'src/images/navbar/tooltip-page-icons/your-report-page.png'
import { Notifications as NotificationsIcon } from '@material-ui/icons'
import { getJwtPayload } from 'src/auth/payload'

export const getTooltipText = (
  name: string,
  viewingAsThirdParty: boolean,
  thirdPartyCompanyName: string,
  isUserOrganisation: boolean,
  routes: OrganisationTabsType,
  organisationId: number,
  isInsuranceUseCase?: boolean
): string | null => {
  const { enableCveAccess } = getJwtPayload()
  const viewingAs = isInsuranceUseCase ? 'Switch company' : 'third party'
  const viewingAs2 = isInsuranceUseCase ? 'portfolio company' : 'third party'
  const viewingAs3 = isInsuranceUseCase ? 'Switch company' : 'Viewing as third party'

  switch (name) {
    case 'Help':
      return 'Book a demo'
    case 'Selected Organisation':
      return 'Switch tenant'
    case 'Selected Company':
      return viewingAsThirdParty ? `Viewing ${viewingAs2}: ${thirdPartyCompanyName}` : `${viewingAs3}`
    case 'Settings':
      return viewingAsThirdParty
        ? `Page not available - currently viewing as ${viewingAs2}`
        : 'Please upgrade your subscription to access this page'
    case 'Alerts':
      if (viewingAsThirdParty) {
        return `Alerts not available - currently viewing as ${viewingAs2}`
      }
      return routes && !routes['/alerts'] ? 'Please upgrade your subscription to access this page' : null
    case 'Search':
      if (!enableCveAccess) {
        return 'Please upgrade your subscription to access search'
      }
      return 'Quick Search - CVE cards'
    case 'External ASM':
      return 'External Attack Surface Management'
    case 'Internal RBVM':
      return isInsuranceUseCase
        ? 'Risk Based Vulnerability Management'
        : 'Internal Vulnerabilities - Risk Based Vulnerability Management'
    case 'User Managment':
      return 'Manage users'
    default:
      return null
  }
}

export const mapTooltipPageImages = {
  'External ASM': ExternalASMPageImage,
  'Internal RBVM': InternalRBVMPageImage,
  'Third Party Risk': ThirdPartyRiskPageImage,
  Intelligence: IntelligencePageImage,
  Profiles: ProfilesPageImage,
  'Deep & Dark Web': DeepDarkWebPageImage,
  Reports: ReportsPageImage,
  'Your Report': YourReportPageImage,
  NotificationsIcon: NotificationsIcon,
  Alerts: AlertsPageImage,
}

export const mapTooltipPageSummary = {
  'External ASM': {
    bulletPoints: [
      `View and manage your cyber risk score`,
      `Manage attack surface issues including vulnerabilities and exposed databases instances`,
      `View digital risk issues such as typo-squatting and breached credentials`,
      `Discover threat intelligence reports relevant to your organisation`,
      `Map view to visualise attack surface issues across the globe`,
    ],
  },
  'Internal RBVM': {
    bulletPoints: [
      `Supercharge your internal vulnerability management by leveraging our award winning patch prioritisation and exploit prediction scores`,
      `Easily import your internal vulnerability scans`,
      `Quickly see which vulnerabilities are known to be exploited or are highly likely to be exploited in the future`,
      `Filter results based on relevant threat categories such as specific threat actors & targeted sectors etc.`,
      `Export a prioritised list of vulnerabilities into a CSV file for further analysis/remediation`,
    ],
  },
  RBVM: {
    bulletPoints: [
      `Supercharge your vulnerability management by leveraging our award winning patch prioritisation and exploit prediction scores`,
      `Easily import your internal vulnerability scans`,
      `Quickly see which vulnerabilities are known to be exploited or are highly likely to be exploited in the future`,
      `Filter results based on relevant threat categories such as specific threat actors & targeted sectors etc.`,
      `Export a prioritised list of vulnerabilities into a CSV file for further analysis/remediation`,
    ],
  },
  'Third Party Risk': {
    bulletPoints: [
      `Single pane view of External ASM portfolio`,
      `Actively monitor and reduce your associated risk and easily assess potential vendors`,
      `View each third-party company’s cyber risk score breakdown including threat and vulnerability score details`,
      `Invite your third-party companies to view their own cyber risk report to remediate any issues`,
      `Uncover information on the most severe vulnerabilities and commonly detected technologies across all third-party companies`,
    ],
  },
  Intelligence: {
    bulletPoints: [
      `Access to our vast analyst-written research database`,
      `Filter relevant intelligence reports based on various threat categories`,
      `Download associated IoCs directly from the intelligence reports`,
      `Easily download and share relevant intelligence reports within your organisation`,
    ],
  },
  Profiles: {
    bulletPoints: [
      `Access to our database of threat actor profiles`,
      `Download associated IoCs`,
      `See relevant intelligence reports linked to threat actors`,
      `Easily share within your organisation`,
      `Download monthly and weekly threat summary reports`,
    ],
  },
  'Deep & Dark Web': {
    bulletPoints: [
      `Easily search relevant deep and dark web sources for keywords of interest`,
      `See associated intelligence reports and threat actor profiles`,
      `Save searches and configure keyword mention alerts`,
    ],
  },
  Reports: {
    bulletPoints: [
      `Download cyber risk reports for your organisation`,
      `Search and download your third-party cyber risk reports`,
      `Access any bespoke reports that you have subscribed to`,
    ],
  },
}
