import { getJwtPayload } from 'src/auth/payload'
import posthog from 'posthog-js'
import { rootStore } from 'src/redux/store'
import { checkInteractionAndTriggerMessage } from 'src/components/in-app-messaging/utils'

// ===================
// GENERAL INTERACTIONS
// ===================
export const userClickedBackToTopButton = `user clicked back to top of page button`
export const userSortedTable = (sortDirection: string, columnName: string) =>
  `user sorted ${columnName} column to ${sortDirection}`

// ===================
// NAVBAR
// ===================
export const userClickedOrpheusLogo = 'user clicked orpheus logo'
export const userClickedOnPageLink = (page: string) => `user clicked on ${page} link`
export const userClickedOnNavbarBottomIcons = (name: string) => `user clicked on ${name} icon on navbar`
export const userLoggedOut = 'user logged out'
export const userToggledNavBar = (direction: 'closed' | 'opened') => `user ${direction} navbar`

// ===================
// FILTERS
// ===================
export const userOpenedDatePicker = 'user opened date picker'
export const userAddedSearchTerm = 'user added search term'
export const userRemovedSearchTermFromFilter = 'user removed search term from filter'
export const userClearedSearch = 'user cleared search'
export const userSubmittedSearch = 'user submitted search'
export const userSelectedDropDownOption = 'user selected drop down option'

// ===================
// PAGES
// ===================
// External ASM
// -- attack surface table
export const userOpenedSectionType = (sectionType: string) => `user opened ${sectionType}`
export const userOpenedAttackSurfaceMapView = 'user opened attack surface map view'
export const userDownloadedAttackSurfaceAssets = 'user downloaded attack surface assets'
// -- cyber threat activity table
export const userOpenedRctaAdversaryGroups = 'user opened rcta adversary groups'
export const userOpenedRctaSection = (sectionTitle: string) => `user opened rcta section ${sectionTitle}`
export const userOpenedRctaAllReports = 'user opened rcta All Reports'
// -- digital risk protection table
export const userOpenedDrpSection = (sectionTitle: string) => `user opened drp ${sectionTitle}`
// -- latest reports
export const userDownloadedCompanyReportExternalASMWidget = 'user downloaded company report'
export const userOpenedPreviousReports = 'user opened previous reports'
// -- risk chart
export const userChangedRiskChartDateRange = (dateRange: string) => `user changed risk chart date range to ${dateRange}`
export const userChangedRiskChartCheckbox = (checkBoxState: { [key: string]: boolean }) =>
  `user changed risk chart check box to thirdPartyRisk: ${checkBoxState.thirdPartyRisk}, sectorAverage: ${checkBoxState.sectorAverage}`

// Third Party Risk
export const userClickedThreatReports = 'user clicked threat reports'
export const userOpenedCveCompanyModal = 'user opened cve company modal'
export const userOpenedScoreSlider = 'user opened score slider'
export const userChangedScoreRange = 'user changed score range'
export const userClickedComparativeRiskChartPoint = 'user clicked comparative risk chart point'
export const userClickedOnMostDetectedTechChart = (technology: string) =>
  `user clicked ${technology} on most detected technologies chart`
export const userToggledLegendOptionMostDetectedTechnologies =
  'user toggled legend option in most detected technology chart'
export const userOpenedAddCompanyModal = 'user opened add company modal'
export const userAddedThirdPartyCompany = 'user added third party company'

// Intelligence Page
export const userOpenedSeveritySlider = 'user opened severity slider'
export const userChangedSeverityRange = 'user changed severity range'
export const userChangedMapView = (mapView: string) => `user changed map view to ${mapView} country`
export const userClickedIntelligencePieChart = (type: string) => `user clicked on ${type} pie chart`
export const userClickedCountryOnMap = (country: string) => `user clicked ${country} on map`

// Vulnerabilities
export const userUploadedFirstCsv = 'user uploaded first csv file'
export const userUploadedCsv = 'user uploaded csv file'
export const userDownloadedCsv = 'user downloaded csv file'
export const userClickedVulnerabilitiesPieChart = (type: string) => `user clicked on ${type} pie chart`
export const userClickedShowAllCves = 'user clicked show all cves'
export const userClickedExploitedInTheWild = 'user clicked exploited in the wild'
export const userClickedExploitedByRansomware = 'user clicked exploited by ransomware'

// Profiles Page
export const userOpenedProfileModal = 'user opened profile modal'
export const userDownloadedSummary = 'user downloaded summary'

// Login Page
export const userLoggedInSuccessfully = 'user logged in successfully'

// Reports page
export const userDownloadedCompanyReportPdf = 'user downloaded company report (pdf)'
export const userDownloadedCompanyReportExcel = 'user downloaded company report (excel)'

// Explore Page
export const userClickedFilterBySource = 'user clicked filter by source'
export const userAppliedSourceFilters = 'user applied source filters'
export const userResetSourceFilters = 'user reset source filters'
export const userClickedMoreOptions = 'user clicked more options'
export const userOpenedCreateAlertModal = 'user opened create alert modal'
export const userOpenedSaveSearchModal = 'user opened save search modal'
export const userCreatedAlert = 'user created alert'
export const userSavedSearch = 'user saved search'
export const userToggledSearchInTableHeader = 'user toggled search in table header'
export const userOpenedModalType = (modalType: string) => `user opened ${modalType} modal`
export const userClickedViewIntrepReports = `user clicked view intrep reports`
export const userClickedViewProfiles = `user clicked view profiles`
export const userDismissedReports = `user dismissed reports`
export const userDismissedProfiles = `user dismissed profiles`
export const userClearedSearchHistory = `user cleared search history`
export const userClickedRecentSearch = `user clicked recent search`
export const userDeletedRecentSearch = `user deleted recent search`
export const userClickedSavedSearch = `user clicked on saved search`
export const userClickedEditSavedSearch = `user clicked edit saved search`
export const userUpdatedSavedSearch = `user updated saved search`
export const userCancelledEditSavedSearch = `user cancelled edit of saved search`
export const userDeletedSavedSearch = `user deleted saved search`

// Report Viewer Page
export const userChangedReportViewerTab = (tabType: string) => `user changed report viewer tab to ${tabType}`
export const userDownloadedCompanyReportFromReportViewer = (reportTitle: string) =>
  `user downloaded company report (${reportTitle === 'summary' ? 'pdf' : 'excel'})`
export const userClickedOnIntrepLinkInThreatTabReportViewer = 'user clicked on intrep link in threat tab'
export const userClickedInfrastructureAndExposuresInVulnerabilitiesTabReportViewer = (infrastructureType: string) =>
  `user clicked infrastructure and exposure tab ${infrastructureType}`
export const userClickedNextCredentialReportViewer = 'user clicked next credential'
export const userClickedPreviousCredentialReportViewer = 'user clicked previous credential'
export const userClickedOnReportViewerChip = (category: string) =>
  `user clicked on report viewer chip with category: ${category.slice(0, -1)}`
export const reportViewerUserTriedToClickOnChipLink = 'report viewer user tried to click on chip link'
export const reportViewerUserTriedToClickOnIntelligenceReport =
  'report viewer user tried to click on intelligence report link'

// Alerts Page
export const userClickedEditDeepDarkWebAlert = `user clicked edit deep and dark web alert`
export const userClickedEditGeneralAlert = `user clicked edit general alert`
export const userSavedEditedAlert = `user saved edited alert`

// Settings Page
export const userSavedSettings = `user saved settings`
export const userOpenedTermsAndConditionsModal = `user opened terms and conditions modal`
export const userEnabledPremiumScanning = `user enabled premium scanning`
export const userDisabledPremiumScanning = `user disabled premium scanning`
export const userRequestedPremiumScan = `user requested premium scan`

// Subscription Page
export const userClickedOnUpgradeNavbar = (pageName: string) =>
  `user clicked on upgrade link from ${pageName} on navbar tooltip`
export const userSubmittedSelfSignUpDetails = `user submitted coupon,email, organisation name and domain details`
export const userSubmittedSelectedPackage = (productName: string) => `${productName} package selected`
export const userPaidSuccessfully = `user paid successfully`
export const userPaidUnsuccessfully = `user paid unsuccessfully`
export const userClickedGoBackOnCheckoutForm = `user clicked go back on checkout form`

// ===================
// MODALS
// ===================
// General
export const userDownloadingDataFromModal = (downloadType: string) => `user downloaded ${downloadType} data from modal`
export const userSetItemStatus = (itemStatus: string, numberOfItems: number) =>
  `user set item status ${itemStatus} for ${numberOfItems} items`
export const userOpenedImageModal = 'user opened image modal'
export const userClickedOnModalChip = (category: string) =>
  `user clicked on modal chip with category: ${category.slice(0, -1)}`
export const userClickedOnModalSourceLink = 'user clicked on modal source link'
export const userChangedModalTab = (tabName: string) => `user changed tab to ${tabName}`
export const userClickedOnRelatedReport = 'user clicked on related report'

// Report Modal
export const userOpenedReportModal = 'user opened report modal'

//Self upgrade modal - your report
export const userSelectedPackage = (productName: string) => `${productName} package selected -your report page`
export const selfUpgradeModalOpen = 'user opened self upgrade modal - your report page'
export const selfUpgradeModalSucceededConfirmation = 'user succeeded upgrade confirmation on self upgrade modal'
export const selfUpgradeModalAttemptedConfirmation =
  'user attempted upgrade confirmation  on self upgrade modal - your report page'
export const userClickedGoBackOnCheckoutFormReportPage = `user clicked go back on checkout form - your report page`
export const userPaidSuccessfullyReportPage = `user paid successfully`
export const selfUpgradeModalCancelledBillingState =
  'user cancelled self upgrade modal when providing billing details - your report page'
export const selfUpgradeModalCancelledPaymentState =
  'user cancelled self upgrade modal when providing payment details - your report page'

// Company Modal
export const userOpenedCompanyModal = 'user opened company modal'
export const userDownloadedCompanyReport = (reportTitle: string) =>
  `user downloaded company report (${reportTitle === 'company_report' ? 'pdf' : 'excel'})`
export const userRequestedReport = 'user requested report'
export const userClickedOnIntrepLinkInThreatTab = 'user clicked on intrep link in threat tab'
export const userClickedInfrastructureAndExposuresInVulnerabilitiesTab = (infrastructureType: string) =>
  `user clicked infrastructure and exposure tab ${infrastructureType}`
export const userClickedPreviousCredential = 'user clicked previous credential'
export const userClickedNextCredential = 'user clicked next credential'
export const userOpenedManageInvites = 'user opened manage invites'
export const userClosedManageInvites = 'user closed manage invites'
export const userResentEmailInvite = 'user resent email invite'
export const userInputValidEmailManageInvites = 'user input valid email manage invites'
export const userInputInvalidEmailManageInvites = 'user input invalid email manage invites'
export const userSuccessfullySentEmailInvite = 'user successfully sent email invite'
export const userUnsuccessfullySentEmailInvite = 'user unsuccessfully sent email invite'
export const userRemovedEmailFromNewInvites = 'user removed email from new invites'

// CVE modal
export const userOpenedCveModal = 'user opened cve modal'
export const userClickedGoToIntelligenceReports = 'user clicked go to intelligence reports'
export const userClickedOnClearnetMention = 'user clicked on clearnet mention'
export const userClickedGoToDeepDarkWebMentions = 'user clicked go to deep and dark web mentions'

// Exploits
export const userOpenedExploitDetails = 'user opened exploit details'
export const userOpenedExploitDetailsModal = 'user opened exploit details modal'
export const userClickedOnExploreExploit = 'user clicked on explore exploit'

// RBVM file upload modal
export const userSuccessfullyUploadedCsvFile = 'user successfully uploaded csv file'
export const userUnsuccessfullyUploadedCsvFile = 'user unsuccessfully uploaded csv file'
export const userSetupTenableIntegration = 'user setup Tenable integration'
export const userSetupQualysIntegration = 'user setup Qualys integration'
export const userSetupRapid7Integration = 'user setup Rapid7 integration'
export const userDeletedRbvmIntegration = 'user deleted RBVM integration'

// visualisation modal
export const userOpenedVisualisationModal = 'user opened visualisation modal'
export const userClosedVisualisationModal = 'user closed visualisation modal'

// company concentration risk graph modal
export const userOpenedCompanyConcentrationRiskModal = 'user opened company concentration risk modal'
export const userClosedCompanyConcentrationRiskModal = 'user closed company concentration risk modal'

// switching company/organisation
export const userViewingAsThirdPartyCompany = 'user viewing as third party company'
export const userViewingAsTenant = 'user viewing as tenant'

export const setupCustomAttributes = () => {
  try {
    const token = getJwtPayload() || {}
    const state = rootStore.getState()
    const { organisationName, firstName, email } = state.auth

    posthog.register({
      user_id: token.userId,
      org_id: token.organisationId,
      company_id: token.companyId,
      org_name: organisationName,
      email,
      name: firstName,
    })
  } catch (e) {
    console.error('Failed to set up custom attributes:', e)
  }
}

export const recordUserInteraction = (interactionName: string) => {
  setupCustomAttributes()

  if (!interactionName) {
    console.error('Cannot record interaction with no name')
    return
  }

  posthog.capture(interactionName)

  // Evaluate the feature flag for in-app messaging
  checkInteractionAndTriggerMessage(interactionName)
}

export const identifyUserInPostHog = () => {
  const token = getJwtPayload() || {}
  const state = rootStore.getState()
  const { organisationName, firstName, email, isNewUser, termsOfUseAcceptedDate } = state.auth

  if (token) {
    // Identify the user in PostHog
    posthog.identify(token.userId, {
      user_id: token.userId,
      org_id: token.userOrganisationId,
      company_id: token.usersCompanyId,
      org_name: organisationName,
      email,
      name: firstName,
      isNewUser,
      termsOfUseAcceptedDate,
    })

    setupCustomAttributes()
  }
}

export const updateOnboardingStatusInPosthog = () => {
  const userId = posthog.get_distinct_id()
  posthog.identify(userId)
  posthog.people.set({
    hasCompletedOnboarding: true,
  })
}

export const clearPostHogSession = () => {
  posthog.reset()
}
