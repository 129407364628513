import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core'
import snackbar from 'src/context/snackbar'
import axios from 'axios'
import { getJwtPayload } from 'src/auth/payload'
import { useDispatch, useSelector } from 'react-redux'
import { Loaders } from 'src/components'
import { gql as GQL } from 'apollo-boost'
import {
  recordUserInteraction,
  selfUpgradeModalAttemptedConfirmation,
  selfUpgradeModalSucceededConfirmation as selfUpgradeModalSucceededConfirmation,
} from 'src/services/posthog'
import client from 'src/network/apollo-client'
import Cookies from 'js-cookie'
import { persistor } from 'src/redux/store'
import { getFirstActiveTab } from 'src/routers/router-helpers'
import { theme } from 'src/styling'

interface ConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  //   onConfirm: () => void
  title: string
}

// =======
// QUERIES
// =======
const UPGRADE_USER = GQL`
  mutation upgradeUser
   {
    upgradeUser
  }
`

const useStyles = makeStyles({
  // checkout page
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    minHeight: '100px',
    minWidth: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonStyles: {
    marginTop: '1rem',
    border: '1px solid white',
    height: '52px',
    width: '100%',
    backgroundColor: theme.colors.ui.blueDark,
    color: theme.colors.ui.white,
    padding: '12px 90px',
    fontSize: '1rem',
    '& .MuiButton-label': { textTransform: 'none', fontWeight: 300 },
    '&:hover': { backgroundColor: theme.colors.ui.blueDark },
    '&.MuiButton-root.Mui-disabled': { color: 'grey' },
  },
  form: {
    width: '400px',
    backgroundColor: 'white',
    padding: '1.4rem',
    borderRadius: '5px',
  },
  // no client secret or stripe promise page
  contentContainer: {
    background: theme.colors.ui.white,
    padding: '3rem',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2.7rem',
  },
  logo: { height: '70px' },

  subscriptionInfoContainer: { flexGrow: 1, marginTop: '1.5%', marginBottom: '1%' },
  chooseSubscriptionHeader: {
    fontSize: '0.9rem',
  },
  priceInfoContainer: { display: 'flex', flexDirection: 'column' },
  discountedPrice: {
    marginBottom: '0.5rem !important',
  },
  discountedPriceSpan: { fontSize: '20px', fontWeight: 'normal' },
})

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, title, content }) => {
  const { userId, organisationId, taxCalcualation } = getJwtPayload()
  const dispatch = useDispatch()
  const { selectedSubscriptionPackage } = useSelector((state) => state.ui)
  const classes = useStyles()
  const { enqueueSnackbar } = snackbar.Consumer()
  const [isLoading, setIsLoading] = useState(false)

  const handleUpgradeConfirmation = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const upgradeSubscription = async () => {
        return axios.post('/payment-portal-lite/upgrade', {
          userId,
          productId: selectedSubscriptionPackage?.productId,
          organisationId,
        })
      }

      await upgradeSubscription().then(() => {
        recordUserInteraction(selfUpgradeModalSucceededConfirmation)
        dispatch({ type: 'SUBSCRIPTION_PACKAGES_INFO', payload: {} })
        dispatch({ type: 'SELECTED_SUBSCRIPTION_PACKAGE', payload: {} })
        client
          .mutate({
            mutation: UPGRADE_USER,
          })
          .then((response) => {
            recordUserInteraction(selfUpgradeModalSucceededConfirmation)
            const { token, expires, thirdPartyCompanyName, organisationName } = JSON.parse(response.data.upgradeUser)
            Cookies.set('auth-token', token, {
              expires: expires,
            })
            dispatch({
              type: 'LOGIN',
              payload: { organisationName, thirdPartyCompanyName },
            })
            dispatch({
              type: 'USER_JUST_LOGGED_IN',
              payload: { hasUserJustLoggedIn: true },
            })
            persistor.flush().then(() => {
              const firstActiveTab = getFirstActiveTab()
              window.location.pathname = firstActiveTab
            })
          })
        enqueueSnackbar(`You subscription has been successfully upgraded.`, {
          variant: 'success',
        })
      })
    } catch (error) {
      console.log(':: error', error)
      recordUserInteraction(selfUpgradeModalAttemptedConfirmation)
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const vat = (Number(selectedSubscriptionPackage?.monthlySubscriptionPrice) / 100) * taxCalcualation
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {title} to {selectedSubscriptionPackage?.description}
      </DialogTitle>
      <DialogContent>
        <Box style={{ margin: '0.5rem 0' }}>Billed monthly</Box>
        <Divider />
        <Box style={{ display: 'flex', flexDirection: 'column', margin: '1% 0' }}>
          <Box className={classes.subscriptionInfoContainer}>
            <Typography className={classes.chooseSubscriptionHeader} style={{ float: 'left' }}>
              {selectedSubscriptionPackage?.description} Subscription
            </Typography>

            <Typography className={classes.chooseSubscriptionHeader} style={{ float: 'right' }}>
              {`£${Number(selectedSubscriptionPackage?.monthlySubscriptionPrice).toFixed(2)}`}
            </Typography>
          </Box>
          <Box className={classes.subscriptionInfoContainer}>
            <Typography className={classes.chooseSubscriptionHeader} style={{ float: 'left' }}>
              Tax ({taxCalcualation}%)
            </Typography>

            <Typography className={classes.chooseSubscriptionHeader} style={{ float: 'right' }}>
              {`£${Number(vat).toFixed(2)}`}
            </Typography>
          </Box>
          <Box className={classes.subscriptionInfoContainer}>
            <Typography className={classes.chooseSubscriptionHeader} style={{ float: 'left', fontWeight: 'bold' }}>
              Total
            </Typography>

            <Typography className={classes.chooseSubscriptionHeader} style={{ float: 'right', fontWeight: 'bold' }}>
              {`£${Number(vat + selectedSubscriptionPackage?.monthlySubscriptionPrice).toFixed(2)}`}
            </Typography>
          </Box>
        </Box>
        <Divider style={{ margin: '3% 0px 3% 0px' }} />
        <DialogContentText>
          Your upgrade will take effect immediately. For the remaining days in your billing cycle, you’ll only be
          charged the difference between your current plan and the new plan.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpgradeConfirmation} color="primary" variant="contained" autoFocus>
          {isLoading ? <Loaders.Spinner size={18} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
